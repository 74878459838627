import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import {
  Page,
  Row,
  Column,
  Spinner,
  Loader,
  toast,
} from '@oliasoft-open-source/react-ui-library';
import {
  operationsDeselected,
  addInitialOperation,
  showImportOperationModalUpdated,
  showCreateActivityModelModalUpdated,
  clearCreateModelFlags,
  createActivityModel,
  importOperationsFromExcel,
  downloadExampleExcel,
  getOperations,
  operationsCleared,
  getSections,
  templateUpdated,
  addSection,
  sectionsDeselected,
} from '~store/entities/activity-model/activity-model';
import { getWellDetails } from '~store/entities/well-details/well-details';
import {
  selectGroupedOperationListWithDefaults,
  selectImportExcelSuccess,
} from '~store/entities/activity-model/selectors';
import { CreateModelErrorsModal } from '~views/create-model/create-model-errors.modal';
import { ImportOperationModal } from '~views/activity-model/operations/import-operation.modal';
import { CreateModelModal } from '~views/create-model/create-model.modal';
import { selectIsPageDisabled } from '~src/store/entities/projects/selectors';
import { projectPages } from '~src/enums/projects';
import { NoItems } from '~views/no-items/no-items';
import { SectionsList } from '~views/activity-model/sections/sections.list';
import translations from '~src/internationalisation/translation-map.json';
import { useTranslation } from 'react-i18next';
import { NO_TEMPLATE } from '~src/enums/settings';
import { SectionDeleteModal } from '~views/activity-model/sections/section-delete.modal';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { DEFAULT_SECTION_INDEX } from '~src/enums/sections';
import { ActivityModelDrawer } from './drawer/activity-model.drawer';
import { OperationForm } from './operations/operation.form';
import { OperationsList } from './operations/operations.list';
import { TasksList } from './tasks/tasks.list';
import { TaskForm } from './tasks/task.form';
import { OperationDeleteModal } from './operations/operation-delete.modal';
import { TaskDeleteModal } from './tasks/task-delete.modal';
import { SectionForm } from './sections/section.form';
import { ImportEstimateModal } from './import-estimate-modal';

const ActivityModel = ({
  operations,
  tasks,
  activeOperation,
  activeTask,
  isFetching,
  isAdding,
  operationsDeselected,
  addInitialOperation,
  isSchematicEmpty,
  isTemplatesEmpty,
  showImportOperationModal,
  showImportOperationModalUpdated,
  showCreateActivityModelModal,
  showCreateActivityModelModalUpdated,
  isPageDisabled,
  clearCreateModelFlags,
  createActivityModel,
  isGenerateFromSchematic,
  groupedOperationList,
  importOperationsFromExcel,
  downloadExampleExcel,
  activeSection,
  getWellDetails,
  operationsCleared,
  importExcelSuccess,
  getOperations,
  getSections,
  sectionItems,
  template,
  templateUpdated,
  addSection,
  sections,
}) => {
  const { t } = useTranslation();
  const { project, company } = useParams();
  const [activeForm, setActiveForm] = useState(null);
  const [operationModalVisible, setOperationModalVisible] = useState(false);
  const [estimateImportModalOpen, setEstimateImportModalOpen] = useState(false);
  const [taskModalVisible, setTaskModalVisible] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [expandedList, setExpandedList] = useState(
    groupedOperationList.map((_, idx) => idx),
  );
  const [sectionsDeleteModalVisible, setSectionsDeleteModalVisible] =
    useState(false);
  const treeRef = useRef(null);
  const expandAllItems = () => treeRef.current.openAll();
  const collapseAllItems = () => treeRef.current.closeAll();
  const openNode = (nodeId) => treeRef.current.open(nodeId);

  useEffect(() => {
    if (project) {
      getOperations(project);
      getWellDetails(project);
      getSections(project);
    }
    return () => operationsCleared();
  }, [project]);

  useEffect(() => {
    if (importExcelSuccess) {
      getOperations(project);
      getSections(project);
      toast({
        message: {
          type: 'Success',
          content: t(translations.successfullImported),
        },
      });
    }
  }, [importExcelSuccess]);

  useEffect(() => {
    if (template !== NO_TEMPLATE) {
      toast({
        message: {
          type: template ? 'Success' : 'Error',
          content: template
            ? `${t(translations.activityModel_generatedFrom)} ${template}`
            : t(translations.activityModel_noTemplateFound),
        },
      });
      templateUpdated(NO_TEMPLATE);
    }
  }, [template]);

  useEffect(() => {
    getWellDetails(project);
  }, [isGenerateFromSchematic]);

  const onClickAddOperation = (
    sectionId = null,
    operationHeaderPosition = null,
    name = 'New Operation',
  ) => {
    operationsDeselected();
    addInitialOperation(project, sectionId, operationHeaderPosition, name);
    setActiveForm('operation');
  };

  const onClickAddSection = () => {
    const section = {
      sectionId: sections[DEFAULT_SECTION_INDEX]?.sectionId,
      position: sectionItems.length,
      type: null,
      wellEventId: null,
      name: t(translations.activityModel_newSection),
    };
    sectionsDeselected();
    addSection(project, section);
    setActiveForm('section');
  };

  const onClickDeleteTask = (task) => {
    setSelectedId(task);
    setTaskModalVisible(true);
  };

  const [headerParams, setHeaderParams] = useState({
    operationHeader: null,
    operationHeaderPosition: null,
  });

  const handleOperationsImportModal = (
    isOpen,
    headerParams = { operationHeader: null, operationHeaderPosition: null },
  ) => {
    showImportOperationModalUpdated(isOpen);
    setHeaderParams(headerParams);
  };

  const forms = {
    section: (
      <SectionForm
        setActiveForm={setActiveForm}
        setSectionsDeleteModalVisible={setSectionsDeleteModalVisible}
        setSelectedId={setSelectedId}
      />
    ),
    operation: (
      <OperationForm
        operation={activeOperation}
        projectId={project}
        isPageDisabled={isPageDisabled}
        setOperationModalVisible={setOperationModalVisible}
        setSelectedId={setSelectedId}
      />
    ),
    task: (
      <TaskForm
        task={activeTask}
        operation={activeOperation}
        isPageDisabled={isPageDisabled}
        projectId={project}
        onClickDeleteTask={onClickDeleteTask}
      />
    ),
  };
  let form = null;
  if (sectionItems.length === 0 && !isFetching) {
    form = (
      <NoItems
        onClickAdd={onClickAddSection}
        showCreateModelModalUpdated={showCreateActivityModelModalUpdated}
        showImportModelModalUpdated={showImportOperationModalUpdated}
        isPageDisabled={isPageDisabled}
        type="activityModel"
        sections={sections}
      />
    );
  }
  if (activeForm) {
    form = forms[activeForm];
  }

  return (
    <>
      <Page padding={false}>
        {isFetching && (
          <Loader text="Fetching..." theme="light" cover>
            <Spinner dark />
          </Loader>
        )}
        <Row spacing={0} height="100%">
          <ActivityModelDrawer />
          <Column scroll width="320px" borderRight>
            <SectionsList
              isAdding={isAdding}
              activeSection={activeSection}
              groupedOperationList={groupedOperationList}
              setSelectedId={setSelectedId}
              isPageDisabled={isPageDisabled}
              setEstimateImportModalOpen={setEstimateImportModalOpen}
              setSectionsDeleteModalVisible={setSectionsDeleteModalVisible}
              setActiveForm={setActiveForm}
              sectionItems={sectionItems}
              onClickAddSection={onClickAddSection}
            />
          </Column>
          {activeSection && (
            <Column scroll width="320px" borderRight>
              <OperationsList
                operations={operations}
                activeSection={activeSection}
                setActiveForm={setActiveForm}
                isFetching={isFetching}
                setOperationModalVisible={setOperationModalVisible}
                setEstimateImportModalOpen={setEstimateImportModalOpen}
                setSelectedId={setSelectedId}
                isAdding={isAdding}
                projectId={project}
                expandAllItems={expandAllItems}
                onClickAddOperation={onClickAddOperation}
                handleOperationsImportModal={handleOperationsImportModal}
                showCreateActivityModelModalUpdated={
                  showCreateActivityModelModalUpdated
                }
                isPageDisabled={isPageDisabled}
                isGenerateFromSchematic={isGenerateFromSchematic}
                expandedList={expandedList}
                setExpandedList={setExpandedList}
                groupedOperationList={groupedOperationList}
              />
            </Column>
          )}
          {(activeOperation || activeForm === 'operation') && (
            <Column scroll borderRight width="320px">
              <TasksList
                tasks={tasks}
                activeOperation={activeOperation}
                setActiveForm={setActiveForm}
                onClickDeleteTask={onClickDeleteTask}
                isAdding={isAdding}
                treeRef={treeRef}
                expandAllItems={expandAllItems}
                collapseAllItems={collapseAllItems}
                openNode={openNode}
                isPageDisabled={isPageDisabled}
                projectId={project}
              />
            </Column>
          )}
          <Column scroll padding>
            {form}
          </Column>
        </Row>
      </Page>
      {operationModalVisible && (
        <OperationDeleteModal
          setModalVisible={setOperationModalVisible}
          operationId={selectedId}
          setActiveForm={setActiveForm}
          projectId={project}
        />
      )}
      {taskModalVisible && (
        <TaskDeleteModal
          setModalVisible={setTaskModalVisible}
          task={selectedId}
          setActiveForm={setActiveForm}
          projectId={project}
        />
      )}
      {(isSchematicEmpty || isTemplatesEmpty) && (
        <CreateModelErrorsModal
          isSchematicEmpty={isSchematicEmpty}
          isTemplatesEmpty={isTemplatesEmpty}
          company={company}
          project={project}
          clearCreateModelFlags={clearCreateModelFlags}
        />
      )}
      {showImportOperationModal && (
        <ImportOperationModal
          projectId={project}
          headerParams={headerParams}
          showImportOperationModalUpdated={showImportOperationModalUpdated}
        />
      )}
      {showCreateActivityModelModal && (
        <CreateModelModal
          projectId={project}
          showCreateModelModalUpdated={showCreateActivityModelModalUpdated}
          isFetching={isFetching}
          createModel={createActivityModel}
          isActivityModel
        />
      )}
      {sectionsDeleteModalVisible && (
        <SectionDeleteModal
          setModalVisible={setSectionsDeleteModalVisible}
          projectSectionId={selectedId}
          setActiveForm={setActiveForm}
          projectId={project}
        />
      )}
      {estimateImportModalOpen && (
        <ImportEstimateModal
          setEstimateImportModalOpen={setEstimateImportModalOpen}
          downloadExampleExcel={downloadExampleExcel}
          importOperationsFromExcel={importOperationsFromExcel}
          projectId={project}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ entities }) => {
  const {
    activityModel: {
      operations,
      isFetching,
      isAdding,
      isSchematicEmpty,
      isTemplatesEmpty,
      showImportOperationModal,
      showCreateActivityModelModal,
      isGenerateFromSchematic,
      activeSection,
      sectionItems,
      template,
      sections,
    },
  } = entities;
  const activeOperation = operations.find((item) => item.active);
  const activeTask = activeOperation?.tasks
    ? activeOperation.tasks.find((item) => item.active)
    : null;

  return {
    operations,
    isFetching,
    isAdding,
    activeOperation,
    activeTask,
    tasks: activeOperation?.tasks,
    isSchematicEmpty,
    isTemplatesEmpty,
    showImportOperationModal,
    showCreateActivityModelModal,
    isPageDisabled: selectIsPageDisabled(entities, projectPages.ACTIVITY_MODEL),
    isGenerateFromSchematic,
    groupedOperationList: selectGroupedOperationListWithDefaults({ entities }),
    activeSection,
    importExcelSuccess: selectImportExcelSuccess({ entities }),
    sectionItems,
    template,
    sections,
  };
};

const mapDispatchToProps = {
  operationsDeselected,
  addInitialOperation,
  showImportOperationModalUpdated,
  showCreateActivityModelModalUpdated,
  clearCreateModelFlags,
  createActivityModel,
  importOperationsFromExcel,
  downloadExampleExcel,
  getOperations,
  getWellDetails,
  operationsCleared,
  getSections,
  templateUpdated,
  addSection,
};

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(ActivityModel),
  { isPage: true },
);

export { Container as ActivityModel };
